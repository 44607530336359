<template>
    <div>
        <vs-input icon-pack="feather" icon="icon-calendar" placeholder="Année" v-model="year" class="is-label-placeholder w-full" type="number" v-on:change="refresh" />
        <br/>
        <vx-card>
            <div style="text-align:center;">
                <Upload ref="Upload" v-on:refresh="refresh" />
                <br/>
            </div>
            <listDate ref="ListDate" reverse="true" empty="false" v-bind:sep=true>
                <template v-slot:item="item">
                    <div>
                        <a target="#" v-on:click="viewFileDoc(item.item)" class="cursor">
                            {{ item.item.data }}
                        </a>
                        <a target="#" v-on:click="deleteFile(item.item)" class="cursor float-right">
                            <vs-icon icon="cancel" size="small" color="red" class="cursor"></vs-icon>
                        </a>
                    </div>
                    <br/><br/>
                </template>

                <!-- HISTORIQUE VIDE -->
                <template v-slot:empty>
                    <vs-divider></vs-divider>
                    <h3 style="text-align:center;">Aucun document pour {{ year }}</h3>
                </template>
            </listDate>
        </vx-card>
    </div>
</template>



<style lang="scss">
</style>

<script>
//v-bind:href=" $srvApi.buildUrl('studio/cloud/') + $route.params.uid"
import autoRefresh from '@/assets/utils/autoRefresh'

import listDate from '@/components/app/listDate.vue'
import Upload from '@/components/app/upload.vue'

export default {
    components:{
        listDate,
        Upload,
    },
    data(){
        return{
            year: (new Date()).getFullYear(),
        }
	},
	mounted:function()
	{
        //autoRefresh
		autoRefresh.SetCallback(()=>{
            //get list fichier serveur
            this.$srvApi.Req('get', 'studio/cloud/'+this.year)
            .then(( rep )=>{
                //prepare data
                let buffer = []
                for( var i in rep.data )
                for( var p=0; p<rep.data[i].length; p++)
                {
                    var month = parseInt(i);
                    buffer.push({
                        date : this.year+"-"+month+"-01 05:00:00",
                        data  : rep.data[i][p]
                    })
                }

                //evite un bug bizar lors de certain refresh
                if(this.$refs.ListDate.addTabYear)
                    this.$refs.ListDate.addTabYear( buffer )
            })
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){

        }
    },
    methods:{
        viewFileDoc:function( file )
        {
            if(file)
                this.$router.push('/admin/file/'+ encodeURI(btoa('studio/cloud/'+file.year+"/"+(file.month+1)+"/"+file.data)) );
        },
        refresh:function()
        {
            autoRefresh.refresh()
        },

        deleteFile( file )
        {
            if( file )
            this.$srvApi.Req('delete', 'studio/cloud/'+file.year+"/"+(file.month+1)+"/"+file.data)
            .then(this.refresh);
        }
    }
};

</script>
